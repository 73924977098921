let commons = require('../utils/commons');

function mobileFilterMenuInit() {
    const menuOpenLink = document.getElementById('mobile-filter-menu-open-link');
    if (menuOpenLink === undefined || !menuOpenLink) {
        console.log('mobileFilterMenuInit: no menuOpenLink found by id "mobile-filter-menu-open-link"');
        return false;
    }
    const menuCloseLink = document.getElementById('mobile-filter-menu-close');
    if (menuCloseLink === undefined || !menuCloseLink) {
        console.log('mobileFilterMenuInit: no menuCloseLink found by id "mobile-filter-menu-close"');
        return false;
    }
    const menuBodyNode = document.getElementsByClassName('filter-menu-body')[0];
    if (menuBodyNode === undefined || !menuBodyNode) {
        console.log('mobileFilterMenuInit: no menuBodyNode found by id "filter-menu-body"');
        return false;
    }
    const menuBackgroundNode = document.getElementsByClassName('filter-menu-background')[0];
    if (menuBackgroundNode === undefined || !menuBackgroundNode) {
        console.log('mobileFilterMenuInit: no menuBackgroundNode found by id "filter-menu-background"');
        return false;
    }
    this.toggleMenu = function() {
        // menu
        console.log('mobileFilterMenuInit: toggleMenu');
        if (commons.hasClassName(menuBodyNode, 'closed')) {
            commons.addClassName(menuBodyNode, 'animated');
            commons.removeClassName(menuBodyNode, 'closed');
            setTimeout(function() {
                commons.removeClassName(menuBodyNode, 'animated');
            }, 350);
        } else {
            commons.addClassName(menuBodyNode, 'animated');
            setTimeout(function() {
                commons.addClassName(menuBodyNode, 'closed');
            }, 350);
        }
        // background
        if (commons.hasClassName(menuBackgroundNode, 'closed')) {
            commons.addClassName(menuBackgroundNode, 'animated');
            commons.removeClassName(menuBackgroundNode, 'closed');
            setTimeout(function() {
                commons.removeClassName(menuBackgroundNode, 'animated');
            }, 250);
        } else {
            commons.addClassName(menuBackgroundNode, 'animated');
            setTimeout(function() {
                commons.addClassName(menuBackgroundNode, 'closed');
            }, 250);
        }
    }
    menuOpenLink.addEventListener('click', function(e) {
        e.preventDefault();
        this.toggleMenu();
    }.bind(this));
    menuCloseLink.addEventListener('click', function(e) {
        e.preventDefault();
        this.toggleMenu();
    }.bind(this));
    menuBackgroundNode.addEventListener('click', function(e) {
        e.preventDefault();
        this.toggleMenu();
    }.bind(this));
}

document.addEventListener('DOMContentLoaded', function(){
    const mobileFilterMenuObj = new mobileFilterMenuInit();
});
